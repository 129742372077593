<template>
    <b-container fluid>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('seedsConfig.companyFarmerInfo')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                        <!-- <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
                             {{  $t('globalTrans.add_new') }}
                        </b-button> -->
                        <b-button class="btn_add_new" size="sm" v-b-modal.modal-4 @click="resetId">
                            <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" bordered striped hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(company_name)="data">
                                        <span class="capitalize">{{ data.item.category_id == 2 ? data.item.farmer_name : data.item.company_name }}</span>
                                        </template>
                                        <template v-slot:cell(company_name_bn)="data">
                                        <span class="capitalize">{{ data.item.category_id == 2 ? data.item.farmer_name_bn : data.item.company_name_bn }}</span>
                                        </template>
                                        <template v-slot:cell(category_id)="data">
                                        {{  data.item.category_id == 2 ? $t('seedsConfig.farmer') : $t('seedsConfig.company') }}
                                        </template>
                                        <template v-slot:cell(mobile_no)="data">
                                        {{$n(0) }}{{ data.item.category_id == 2 ? $n(data.item.mobile_no, { useGrouping: false }) : $n(data.item.contact_person_mobile, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(division_id)="data">
                                        <span class="capitalize">{{ getAddressInfo(data.item.division_id, 'division') }}</span>
                                        </template>
                                        <template v-slot:cell(district_id)="data">
                                        <span class="capitalize">{{ getAddressInfo(data.item.district_id, 'district') }}</span>
                                        </template>
                                        <template v-slot:cell(upazilla_id)="data">
                                        <span class="capitalize">{{ getAddressInfo(data.item.upazilla_id, 'upazila') }}</span>
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <!-- <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                            <span v-if="data.item.status === 1">
                                                <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="fas fa-window-close m-0"></i></b-button>
                                            </span>
                                            <span v-if="data.item.status === 2">
                                                <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="fa fa-check m-0"></i></b-button>
                                            </span> -->
                                            <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 size="sm" @click="edit(data.item)">
                                                <i class="ri-ball-pen-fill"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" size="sm" @click="remove(data.item)" v-if="data.item.status === 1">
                                                <i class="fas fa-toggle-on"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" size="sm" @click="remove(data.item)" v-if="data.item.status === 2">
                                                <i class="fa fa-toggle-off"></i>
                                            </a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>

    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { companyFarmerInfoList, companyFarmerInfoToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form
    },
    data () {
        return {
            search: {
                company_name: '',
                user_id: 0,
                org_id: this.$store.state.dataFilters.orgId
            },
            applyByList: [
                { value: 1, text: this.$t('seedsSeeds.company') },
                { value: 2, text: this.$t('seedsSeeds.farmer') },
                { value: 3, text: this.$t('seedsSeeds.dealer') }
            ],
            farmerCompanyList: []
        }
    },
    computed: {
        orgList: function () {
            const tmpData = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
            return tmpData.map(item => {
                if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
                } else {
                return { value: item.value, text: item.text }
                }
            })
        },
        formTitle () {
            return (this.editItemId === 0) ? this.$t('seedsConfig.companyFarmerInfo') + ' ' + this.$t('globalTrans.entry') : this.$t('seedsConfig.companyFarmerInfo') + ' ' + this.$t('globalTrans.update')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('globalTrans.name'), class: 'text-center' },
                { label: this.$t('seedsConfig.phone'), class: 'text-center' },
                { label: this.$t('globalTrans.type'), class: 'text-center' },
                { label: this.$t('globalTrans.address'), class: 'text-center' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'en') {
                keys = [
                { key: 'index' },
                { key: 'company_name' },
                { key: 'mobile_no' },
                { key: 'category_id' },
                { key: 'address' },
                { key: 'status' },
                { key: 'action' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'company_name_bn' },
                { key: 'mobile_no' },
                { key: 'category_id' },
                { key: 'address_bn' },
                { key: 'status' },
                { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
        this.loadData()
    },
    mounted () {
        core.index()
    },
    watch: {
        'search.applicant_type_id': function (newVal, oldVal) {
            // this.farmerCompanyList = this.farmerCompany(newVal)
        }
    },
    methods: {
        farmerCompany (applyBy) {
        const Datat =  RestApi.getData(seedFertilizerServiceBaseUrl, 'common-dropdowns', null).then(response => {
            const tmpData = response.data.companyFarnerList.filter(item => item.status === 1 && item.category_id === applyBy.value)
             return tmpData.map((item) => {
                    if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.com_text_bn }
                    } else {
                    return { value: item.value, text: item.com_text_en }
                    }
            })
      })
      return Datat
        },
        dataChange () {
            this.loadData()
        },
        default () {
            return {
                id: this.rows.length,
                org_id: 0,
                category_id: 1,
                company_name: '',
                company_name_bn: '',
                address: '',
                address_bn: '',
                contact_person_mobile: '',
                contact_person_email: '',
                farmer_name: '',
                farmer_name_bn: '',
                mobile_no: '',
                nid: '',
                division_id: '',
                district_id: '',
                upazilla_id: '',
                editable: false
            }
        },
        async searchData () {
            this.loadData()
        },
        remove (item) {
        this.changeStatus(seedFertilizerServiceBaseUrl, companyFarmerInfoToggleStatus, item, 'seeds', 'companyFarmerList')
        },
        loadData () {
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(seedFertilizerServiceBaseUrl, companyFarmerInfoList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', response.data.data)
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getAddressInfo (id, type) {
            if(id && type == 'division') {
                const div = this.$store.state.commonObj.divisionList.find(item => item.value === id)
                if(div) {
                    return this.$i18n.locale === 'bn' ? div.text_bn : div.text_en
                }
            }

            if(id && type == 'district') {
                const div = this.$store.state.commonObj.districtList.find(item => item.value === id)
                if(div) {
                    return this.$i18n.locale === 'bn' ? div.text_bn : div.text_en
                }
            }

            if(id && type == 'upazila') {
                const div = this.$store.state.commonObj.upazilaList.find(item => item.value === id)
                if(div) {
                    return this.$i18n.locale === 'bn' ? div.text_bn : div.text_en
                }
            }
        }
    }
}
</script>
