<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                    <ValidationProvider name="Organization" vid='org_id' rules="required|min_value:1">
                      <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('globalTrans.organization')"
                            label-for="org_id"
                            slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{ $t('globalTrans.organization')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                            plain
                            v-model="companyFarmerInfo.org_id"
                            :options="organizationtList"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                        >
                            <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                            </template>
                        </b-form-select>
                        <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                        </div>
                        </b-form-group>
                        </ValidationProvider>
                      <ValidationProvider name="Company/Farmer Info" vid='category_id' rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="warehouse_state"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('seedsConfig.companyFarmerInfo')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-radio-group
                                v-model="companyFarmerInfo.category_id"
                                :options="options"
                                class="mb-3"
                                value-field="item"
                                text-field="name"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-radio-group>
                            <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>

                    <div>
                    <b-tabs content-class="mt-3 ml-4">
                    <b-tab :active="select"><p>
                        <ValidationProvider name="Company Name (En)" vid="company_name" :rules="rule1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="company_name"
                            slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                            {{$t('seedsConfig.companyNameEn')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            id="company_name"
                            v-model="companyFarmerInfo.company_name"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Company Name (Bn)" vid="company_name_bn" :rules="rule1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="company_name_bn"
                            slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                            {{$t('seedsConfig.companyNameBn')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            id="company_name_bn"
                            v-model="companyFarmerInfo.company_name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Address (En)" vid="address">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="address"
                            slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                            {{$t('seedsConfig.addressEn')}}
                            </template>
                            <b-form-input
                            id="address"
                            v-model="companyFarmerInfo.address"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Address (Bn)" vid="address_bn">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="address_bn"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('seedsConfig.addressBn')}}
                                </template>
                                <b-form-input
                                id="address_bn"
                                v-model="companyFarmerInfo.address_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Contact Person's Phone" vid="contact_person_mobile" rules ="numeric|min:11">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="contact_person_mobile"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('seedsConfig.contactPersonPhone')}}
                                </template>
                                <b-form-input
                                type="number"
                                id="contact_person_mobile"
                                v-model="companyFarmerInfo.contact_person_mobile"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Contact Person's Email" vid="contact_person_email">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="contact_person_email"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('seedsConfig.contactPersonEmail')}}
                                </template>
                                <b-form-input
                                type="email"
                                id="contact_person_email"
                                v-model="companyFarmerInfo.contact_person_email"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                    </p></b-tab>
                    <b-tab :active="select2"><p>
                      <ValidationProvider name="Farmer Id" vid="farmer_id">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="farmer_id"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('seedsConfig.farmer_id') }} <span class="text-danger"></span>
                          </template>
                          <b-form-input
                              id="farmer_id"
                              v-model="companyFarmerInfo.farmer_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                      </b-form-group>
                      </ValidationProvider>
                        <ValidationProvider name="Farmer Name (En)" vid="farmer_name" :rules="rule2">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="farmer_name"
                            slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                            {{$t('seedsConfig.farmerNameEn')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            id="farmer_name"
                            v-model="companyFarmerInfo.farmer_name"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Farmer Name (Bn)" vid="farmer_name_bn" :rules="rule2">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="farmer_name_bn"
                            slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                            {{$t('seedsConfig.farmerNameBn')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            id="farmer_name_bn"
                            v-model="companyFarmerInfo.farmer_name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Contact Number" vid="mobile_no" rules ="numeric|min:11">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="mobile_no"
                            slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                            {{$t('seedsConfig.contactNumber')}}
                            </template>
                            <b-form-input
                            type="number"
                            id="mobile_no"
                            v-model="companyFarmerInfo.mobile_no"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="NID" vid="nid" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="nid"
                            slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                            {{$t('seedsConfig.nId')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            id="nid"
                            v-model="companyFarmerInfo.nid"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                         <ValidationProvider name="Division" vid='division_id' :rules="rule2">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="division"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('globalTrans.division')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="companyFarmerInfo.division_id"
                                :options="divisionList"
                                id="division_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="District" vid='district_id' :rules="rule2">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="district"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('globalTrans.district')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="companyFarmerInfo.district_id"
                                :options="districtList"
                                id="district_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Upazila" vid='upazilla_id' :rules="rule2">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="upazila_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('globalTrans.upazila')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="companyFarmerInfo.upazilla_id"
                                :options="upazilaList"
                                id="upazila_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Address (En)" vid="type_name">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="address"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{$t('seedsConfig.addressEn')}}
                            </template>
                            <b-form-input
                            id="address"
                            v-model="companyFarmerInfo.address"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Address (Bn)" vid="address_bn">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="address_bn"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('seedsConfig.addressBn')}}
                                </template>
                                <b-form-input
                                id="address_bn"
                                v-model="companyFarmerInfo.address_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </p></b-tab>
                    </b-tabs>
                    </div>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { companyFarmerInfoStore, companyFarmerInfoUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    this.companyFarmerInfo.org_id = this.$store.state.dataFilters.orgId
    if (this.id) {
      const tmp = this.getCompanyFarmerData()
      this.companyFarmerInfo = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      companyFarmerInfo: {
        id: '',
        org_id: 0,
        category_id: 1,
        company_name: '',
        company_name_bn: '',
        address: '',
        address_bn: '',
        contact_person_mobile: '',
        contact_person_email: '',
        farmer_id: '',
        farmer_name: '',
        farmer_name_bn: '',
        mobile_no: '',
        nid: '',
        division_id: '',
        district_id: '',
        upazilla_id: '',
        status: '1'
      },
      tab: 'active',
      tab2: '',
      rule1: 'required',
      rule2: '',
      select: true,
      select2: false,
      districtList: [],
      upazilaList: [],
      options: [
          { item: 1, name: this.$t('seedsConfig.company') },
          { item: 2, name: this.$t('seedsConfig.farmer') }
        ]
    }
  },
  computed: {
    organizationtList: function () {
      return this.$store.state.commonObj.organizationProfileList
    },
    divisionList: function () {
        return this.$store.state.commonObj.divisionList
    }
  },
  watch: {
    'companyFarmerInfo.category_id': function (newVal, oldVal) {
        if (newVal === 1) {
            this.select = true
            this.rule1 = 'required'
            this.select2 = false
            this.rule2 = ''
        } else {
            this.select = false
            this.rule1 = ''
            this.select2 = true
            this.rule2 = 'required'
        }
    },
    'companyFarmerInfo.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'companyFarmerInfo.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    }
  },
  methods: {
    getCompanyFarmerData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
     async createData () {
        var insertData = []
         if (this.companyFarmerInfo.category_id === 1) {
              const params = Object.assign({}, this.companyFarmerInfo, {
                    farmer_id: '',
                    farmer_name: '',
                    farmer_name_bn: '',
                    mobile_no: '',
                    nid: '',
                    division_id: '',
                    district_id: '',
                    upazilla_id: ''
                })
                insertData = params
         } else {
             const params = Object.assign({}, this.companyFarmerInfo, {
                    company_name: '',
                    company_name_bn: '',
                    contact_person_mobile: '',
                    contact_person_email: ''
                })
                insertData = params
         }

      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.companyFarmerInfo.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${companyFarmerInfoUpdate}/${this.id}`, insertData)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, companyFarmerInfoStore, insertData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('mutateWarehouseProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    }
  }
}
</script>
