// cropType api
export const cropList = '/seeds/config/crop-types/list'
export const cropTypeStore = '/seeds/config/crop-types/store'
export const cropTypeUpdate = '/seeds/config/crop-types/update'
export const cropTypeToggleStatus = '/seeds/config/crop-types/toggle-status'
export const cropTypeDestroy = '/seeds/config/crop-types/destroy'

// program type api
export const programTypeList = 'seeds/config/program-types/list'
export const programTypeStore = 'seeds/config/program-types/store'
export const programTypeUpdate = 'seeds/config/program-types/update'
export const programTypeToggleStatus = 'seeds/config/program-types/toggle-status'
export const programTypeDestroy = 'seeds/config/program-types/destroy'

// cropName api
export const cropNameList = '/seeds/config/crop-name/list'
export const cropNameStore = '/seeds/config/crop-name/store'
export const cropNameUpdate = '/seeds/config/crop-name/update'
export const cropNameToggleStatus = '/seeds/config/crop-name/toggle-status'
export const cropNameDestroy = '/seeds/config/crop-name/destroy'

// masterCategory api
export const masterCategoryList = '/seeds/config/master-category/list'
export const masterCategoryStore = '/seeds/config/master-category/store'
export const masterCategoryUpdate = '/seeds/config/master-category/update'
export const masterCategoryToggleStatus = '/seeds/config/master-category/toggle-status'
export const masterCategoryDestroy = '/seeds/config/master-category/destroy'

// masterSubType api
export const masterSubTypeList = '/seeds/config/master-sub-types/list'
export const masterSubTypeStore = '/seeds/config/master-sub-types/store'
export const masterSubTypeUpdate = '/seeds/config/master-sub-types/update'
export const masterSubTypeToggleStatus = '/seeds/config/master-sub-types/toggle-status'
export const masterSubTypeDestroy = '/seeds/config/master-sub-types/destroy'

// acrryover/fresh api
export const carryoverList = 'seeds/config/carryovers/list'
export const carryoverStore = 'seeds/config/carryovers/store'
export const carryoverUpdate = 'seeds/config/carryovers/update'
export const carryoverToggleStatus = 'seeds/config/carryovers/toggle-status'
export const carryoverDestroy = 'seeds/config/carryovers/destroy'

// price type api
export const priceTypeList = 'seeds/config/price-types/list'
export const priceTypeStore = 'seeds/config/price-types/store'
export const priceTypeUpdate = 'seeds/config/price-types/update'
export const priceTypeToggleStatus = 'seeds/config/price-types/toggle-status'
export const priceTypeDestroy = 'seeds/config/price-types/destroy'

// variety api
export const varietyList = '/seeds/config/variety/list'
export const varietyStore = '/seeds/config/variety/store'
export const varietyUpdate = '/seeds/config/variety/update'
export const varietyToggleStatus = '/seeds/config/variety/toggle-status'
export const varietyDestroy = '/seeds/config/variety/destroy'

// seed-class api
export const seedClassList = '/seeds/config/seed-class/list'
export const seedClassStore = '/seeds/config/seed-class/store'
export const seedClassUpdate = '/seeds/config/seed-class/update'
export const seedClassToggleStatus = '/seeds/config/seed-class/toggle-status'
export const seedClassDestroy = '/seeds/config/seed-class/destroy'

// production-source api
export const productionSourceList = '/seeds/config/production-source/list'
export const productionSourceStore = '/seeds/config/production-source/store'
export const productionSourceUpdate = '/seeds/config/production-source/update'
export const productionSourceToggleStatus = '/seeds/config/production-source/toggle-status'
export const productionSourceDestroy = '/seeds/config/production-source/destroy'

// measurement-unit api
export const measurementUnitList = '/seeds/config/measurement-unit/list'
export const measurementUnitStore = '/seeds/config/measurement-unit/store'
export const measurementUnitUpdate = '/seeds/config/measurement-unit/update'
export const measurementUnitToggleStatus = '/seeds/config/measurement-unit/toggle-status'
export const measurementUnitDestroy = '/seeds/config/measurement-unit/destroy'

// production-season api
export const productionSeasonList = '/seeds/config/production-season/list'
export const productionSeasonStore = '/seeds/config/production-season/store'
export const productionSeasonUpdate = '/seeds/config/production-season/update'
export const productionSeasonToggleStatus = '/seeds/config/production-season/toggle-status'
export const productionSeasonDestroy = '/seeds/config/production-season/destroy'

// packet variety api
export const packetVarietyList = 'seeds/config/packet-varieties/list'
export const packetVarietyStore = 'seeds/config/packet-varieties/store'
export const packetVarietyUpdate = 'seeds/config/packet-varieties/update'
export const packetVarietyToggleStatus = 'seeds/config/packet-varieties/toggle-status'
export const packetVarietyDestroy = 'seeds/config/packet-varieties/destroy'

// price-info api
export const priceInfoList = 'seeds/config/price-infos/list'
export const varietiesNameWise = 'seeds/config/price-infos/varieties-name-wise'
export const priceInfoStore = 'seeds/config/price-infos/store'
export const priceInfoUpdate = 'seeds/config/price-infos/update'
export const priceInfoToggleStatus = 'seeds/config/price-infos/toggle-status'
export const priceInfoDestroy = 'seeds/config/price-infos/destroy'

// zone-office api
export const zoneOfficeList = '/seeds/config/zone-ofc/list'
export const zoneOfficeStore = '/seeds/config/zone-ofc/store'
export const zoneOfficeUpdate = '/seeds/config/zone-ofc/update'
export const zoneOfficeToggleStatus = '/seeds/config/zone-ofc/toggle-status'
export const zoneOfficeDestroy = '/seeds/config/zone-ofc/destroy'

// spc-office api
export const spcOfficeList = '/seeds/config/spc-ofc/list'
export const spcOfficeStore = '/seeds/config/spc-ofc/store'
export const spcOfficeUpdate = '/seeds/config/spc-ofc/update'
export const spcOfficeToggleStatus = '/seeds/config/spc-ofc/toggle-status'
export const spcOfficeDestroy = '/seeds/config/spc-ofc/destroy'

// prerequisite checklist api
export const prerequisiteChecklistList = 'seeds/config/prerequisite-checklists/list'
export const prerequisiteChecklistStore = 'seeds/config/prerequisite-checklists/store'
export const prerequisiteChecklistUpdate = 'seeds/config/prerequisite-checklists/update'
export const prerequisiteChecklistToggleStatus = 'seeds/config/prerequisite-checklists/toggle-status'
export const prerequisiteChecklistDestroy = 'seeds/config/prerequisite-checklists/destroy'

// company/farmer info api
export const companyFarmerInfoList = 'seeds/config/company-farmers/list'
export const companyFarmerInfoStore = 'seeds/config/company-farmers/store'
export const companyFarmerInfoUpdate = 'seeds/config/company-farmers/update'
export const companyFarmerInfoToggleStatus = 'seeds/config/company-farmers/toggle-status'
export const companyFarmerInfoDestroy = 'seeds/config/company-farmers/destroy'

export const reportHeadingList = '/seeds/config/report-heading/list'
export const reportHeadingStore = '/seeds/config/report-heading/store'
export const reportHeadingUpdate = '/seeds/config/report-heading/update'
export const reportToggleStatus = '/seeds/config/report-heading/toggle-status'
export const reportHeaderDestroy = '/seeds/config/report-heading/destroy'

export const productStockReport = '/seeds/stock-manage/sd-stock/report'
// dealer setup
export const dealerSetupList = '/fertilizer/config/dealer-setup/seedsAlllist'
export const dealerSetupStore = '/fertilizer/config/dealer-setup/store'
export const dealerSetupUpdate = '/fertilizer/config/dealer-setup/update'
export const dealerSetupToggleStatus = '/fertilizer/config/dealer-setup/toggle-status'
export const dealerSetupDestroy = '/fertilizer/config/dealer-setup/destroy'

// production-source Type api
export const productionSourceTypeList = '/seeds/config/production-source-Type/list'
export const productionSourceTypeStore = '/seeds/config/production-source-Type/store'
export const productionSourceTypeUpdate = '/seeds/config/production-source-Type/update'
export const productionSourceTypeToggleStatus = '/seeds/config/production-source-Type/toggle-status'
export const productionSourceTypeDestroy = '/seeds/config/production-source-Type/destroy'
